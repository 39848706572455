import './global.css'
import './index.css'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { UserProvider } from './contexts/UserContext.tsx';
import { Login } from './components/Login.tsx';
import { Profile } from './components/Profile.tsx';
import { Layout } from './components/Layout.tsx';
import { DocumentListContainer } from './components/DocumentList.tsx';
import { IsAuthenticated } from './components/IsAuthenticated.tsx';
import { ThreadsContainer } from './components/Threads.tsx';
import { ErrorFallback } from './components/ErrorFallback.tsx';
import { WebSocketProvider } from './contexts/WebSocketContext.tsx';
import { initializeSentry } from './services/sentry.ts';
import { NewConversation } from './components/Assistant/NewConversation.tsx';
import { ExistingConversation } from './components/Assistant/ExistingConversation.tsx';
import { Ask } from './components/Assistant/Ask.tsx';
import { FeatureFlagProvider } from './contexts/FeatureFlagContext.tsx';
import { Settings } from './components/Settings.tsx';
import { ResourceSearchPageContainer } from './components/Resources/ResourceSearchPage.tsx';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { LayoutProvider } from './contexts/LayoutContext.tsx';
import { ReportsPageContainer } from './components/Reports/ReportsPage.tsx';
import { ReportWriterPage } from './components/Reports/ReportWriterPage.tsx';
import { ToastProvider } from './contexts/ToastContext.tsx';
import { IntegrationPageContainer } from './components/Integration/IntegrationPage.tsx';
import { IntegrationSetupPageContainer } from './components/Integration/IntegrationSetupPage.tsx';
import { IntegrationDetailPageContainer } from './components/Integration/IntegrationDetailPage.tsx';
import { DossierPageContainer } from './components/Dossier/DossierPage.tsx';
import { DossierDetailPageContainer } from './components/Dossier/DossierDetailPage.tsx';
import { HomePageContainer } from './components/Home/HomePage.tsx';
import { Signout } from './components/Signout/Signout.tsx';
import { reactErrorHandler } from '@sentry/react';

initializeSentry();


const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/",
    element: <Layout component={<IsAuthenticated><Login /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/desk",
    element: <Layout component={<IsAuthenticated><></></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/home",
    element: <Layout component={<IsAuthenticated><HomePageContainer /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/library",
    element: <Layout component={<IsAuthenticated><DocumentListContainer /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/search",
    element: <Layout component={<IsAuthenticated><ResourceSearchPageContainer /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/reports",
    element: <Layout component={<IsAuthenticated><ReportsPageContainer /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/profile",
    element: <Layout component={<IsAuthenticated><Profile /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/settings",
    element: <Layout component={<IsAuthenticated><Settings /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/threads",
    element: <Layout component={<IsAuthenticated><ThreadsContainer /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/assistant/ask/",
    element: <Layout component={<IsAuthenticated><Ask /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/dossier/:dossier_id/assistant/ask/",
    element: <Layout component={<IsAuthenticated><Ask /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/assistant/ask/new/",
    element: <Layout component={<IsAuthenticated><NewConversation /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/dossier/:dossier_id/assistant/ask/new/",
    element: <Layout component={<IsAuthenticated><NewConversation /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/assistant/conversation/:conversation_id",
    element: <Layout component={<IsAuthenticated><ExistingConversation /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/dossier/:dossier_id/assistant/conversation/:conversation_id",
    element: <Layout component={<IsAuthenticated><ExistingConversation /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/report/:report_id",
    element: <Layout component={<IsAuthenticated><ReportWriterPage /></IsAuthenticated>} tall={true} scrollable={false} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/dossier/:dossier_id/report/:report_id",
    element: <Layout component={<IsAuthenticated><ReportWriterPage /></IsAuthenticated>} tall={true} scrollable={false} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/integrations",
    element: <Layout component={<IsAuthenticated><IntegrationPageContainer /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/integrations/setup/:integration_code_name",
    element: <Layout component={<IsAuthenticated><IntegrationSetupPageContainer /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/integrations/:integration_code_name",
    element: <Layout component={<IsAuthenticated><IntegrationDetailPageContainer /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/dossiers",
    element: <Layout component={<IsAuthenticated><DossierPageContainer /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/dossier/:dossier_id",
    element: <Layout component={<IsAuthenticated><DossierDetailPageContainer /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/signout",
    element: <Layout component={<IsAuthenticated><Signout /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
]);

ReactDOM.createRoot(document.getElementById('root')!, {
  onRecoverableError: reactErrorHandler(),
}).render(
  // <React.StrictMode>
  <Provider store={store}>
    <ToastProvider>
        <WebSocketProvider>
          <UserProvider>
            <FeatureFlagProvider>
              <LayoutProvider>
                <RouterProvider router={router} />
              </LayoutProvider>
            </FeatureFlagProvider>
          </UserProvider>
        </WebSocketProvider>
    </ToastProvider>
  </Provider>
  // </React.StrictMode>,
)
