import { AssistantAskMode, DESIA_EVENT, Dossier, ResponseChatList } from "../../types/types";
import { TypographyBody, TypographyH3, TypographyLabel } from "../ui/Typography";
import { ChatBox } from "./ChatBox";
import { SuggestionCard } from "./SuggestionCard";
import { useEffect, useRef } from "react";
import { useSocketQuery } from "@/hooks/useSocketQuery";
import { getTimestamp } from "@/utils/utils";
import { ChatList } from "../ChatList";
import { DossierBreadcrumb } from "../Dossier/DossierBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { fetchDossierDetail } from "../Dossier/dossierSlice";
import ImgNewAsk from '../../assets/img-new-ask.svg'
import SuggestionCardHandshake from "@/assets/SuggestionCardHandshake";
import SuggestionCardChart from "@/assets/SuggestionCardChart";
import SuggestionCardBriefcase from "@/assets/SuggestionCardBriefcase";
import { actions as assistantActions } from './assistantSlice';

interface Props {
    dossier?: Dossier,
    expertModeAvailable: boolean;
    hasSourceSelector: boolean;
    handleSubmit: ({ message, }: { message: string, mode: AssistantAskMode }) => void;
}

export function NewAsk({ dossier, expertModeAvailable, handleSubmit }: Props) {
    const dossierDetails = useSelector((state: RootState) => state.dossier.dossierDetails);
    const assistantStore = useSelector((state: RootState) => state.assistant);

    const dispatch = useDispatch<AppDispatch>();
    const { state: { error, loading } } = useSocketQuery({
        event: DESIA_EVENT.CHAT_LIST,
        request: {
            requestId: "list",
            timestamp: getTimestamp(),
            params: {}
        },
        options: {
            manual: false,
            cacheTimeoutMs: 60_000,
            callback: (response) => {
                dispatch(assistantActions.fetchThreads(response.data as ResponseChatList[]))
            }
        }
    });

    const containerRef = useRef<HTMLDivElement>(null)
    const appDispatch = useDispatch<AppDispatch>()

    const dossierDetail = dossierDetails[dossier?.id || '']?.data || undefined

    useEffect(() => {
        // prevent scroll down after loading lists since it saves conversation scroll position when you navigate back
        if (!loading) {
            containerRef.current?.scrollIntoView({ behavior: 'instant', block: 'start' })
        }
    }, [loading])

    useEffect(() => {
        if (!dossier) return
        appDispatch(fetchDossierDetail(dossier.id))
    }, [dossier])

    return (
        <>
            <div className="flex flex-col justify-center align-center h-full -mt-20">
                <div className="h-[1px] mb-10" ref={containerRef}>

                </div>
                {dossier && (
                    <DossierBreadcrumb
                        dossier={dossier}
                        component={
                            <TypographyBody isStrong={true} className="whitespace-nowrap">
                                New chat
                            </TypographyBody>
                        }
                    />
                )}
                <div className="flex flex-col gap-16 justify-center align-center w-full mt-20 mx-auto">
                    <div className="flex flex-col gap-0">
                        <img src={ImgNewAsk} className="h-[186px] w-[343px] sm:h-[240px] sm:w-[440px] mx-auto" />

                        <div className="flex flex-col sm:flex-row gap-3 sm:gap-8 items-center mx-auto -mt-8">
                            <TypographyH3 className="w-[200px] text-center sm:text-right">Ask Desia</TypographyH3>
                            <div className="h-8 w-[1px] bg-system-body hidden sm:!flex"></div>
                            <TypographyLabel className="text-system-body text-center sm:text-left w-[200px] hidden sm:!flex">
                                Level up your investment
                                <br /> research. Minutes, not hours.
                            </TypographyLabel>

                            <TypographyLabel className="text-system-body text-center sm:text-left flex sm:!hidden">
                                Level up your investment research.
                                <br />
                                Minutes, not hours.
                            </TypographyLabel>
                        </div>
                    </div>

                    <div className="flex flex-col gap-8 justify-center">
                        <ChatBox
                            expertModeAvailable={expertModeAvailable}
                            handleSubmit={handleSubmit}
                            additionalControls={true}
                            isConversation={false}
                            showBorder={false}
                            dossierDetail={dossierDetail}
                            showFilePreview={true}
                            canStop={false}
                            sourceType={dossier ? 'dossier' : 'ask'}
                        />

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
                            <SuggestionCard
                                text="What recent mergers or acquisitions have occurred in the telecom sector?"
                                highlights={['recent mergers', 'acquisitions']}
                                bgColor="#FFE3E3"
                                textColor='#7633AA'
                                handleSubmit={handleSubmit}
                                icon={<SuggestionCardHandshake className="size-6 shrink-0" />}
                            />
                            <SuggestionCard
                                text="What are the key factors driving NVIDIA's revenue growth?"
                                highlights={["NVIDIA's revenue growth"]}
                                bgColor="#DAFFE0"
                                textColor='#025477'
                                handleSubmit={handleSubmit}
                                icon={<SuggestionCardChart className="size-6 shrink-0" />}
                            />
                            <SuggestionCard
                                text="Determine the organisational changes needed post-acquisition."
                                highlights={['organisational']}
                                bgColor="#CEF4FD"
                                textColor='#2D4974'
                                handleSubmit={handleSubmit}
                                icon={<SuggestionCardBriefcase className="size-6 shrink-0" />}
                            />
                        </div>
                    </div>

                    <div className="mt-[0px] sm:mt-[56px]">
                        <ChatList
                            threads={assistantStore.list}
                            loading={loading && assistantStore.list.length === 0}
                            error={error}
                            type='chat'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
