import "./Chat.css";

import { normaliseDocumentId, notEmpty, checkIfStringHasContent } from "../utils/utils";
import { UserMessage, SourceDocument, SystemMessage, Citation, SourceType } from "../types/types";
import { filterDocumentsByCited, getGlobalUniqueDocuments } from "../utils/components";
import { PreviewSources } from "./Assistant/PreviewSources";
import { FinalAnswer } from "./Assistant/FinalAnswer";
import { Question } from "./Assistant/Question";
import { PlannerSteps } from "./PlannerSteps";
import { ReactNode, useContext, useState } from "react";
import { TypographyBody, TypographyLabel } from "./ui/Typography";
import ArrowRight from "@/assets/ArrowRight";
import { Button } from "./ui/button";
import { RotateCcw, Sparkles, ThumbsDown, ThumbsUp } from "lucide-react";
import { FeedbackDialog } from "./FeedbackDialog";
import { handleError } from "@/utils/handleError";
import { captureFeedback } from "@sentry/react";
import { UserContext } from "@/contexts/UserContext";
import { ToastContext } from "@/contexts/ToastContext";

export function UserChatMessage(props: { message: UserMessage, compact?: boolean, component?: ReactNode }) {
    return (
        <div className="max-w-[710px] flex">
            <Question message={props.message.query} compact={props.compact} component={props.component} />
        </div>
    )
}

function getFilteredDocuments(allDocuments: SourceDocument[]) {
    return getGlobalUniqueDocuments(allDocuments);
}

export type SelectedCitation = {
    messageId: string; // for now hack on frontend by joining conversationid with timestamp
    documentIds: string[],
}

export function getMessageId(message: SystemMessage, messageNumber?: number) {
    const prefix = message.conversationId || message.requestId;
    const suffix = typeof messageNumber === 'number' ? messageNumber : message.data.isFinished ? message.timestamp : "latest";
    return `${prefix}_${suffix}`;
}

function orderFileFirst(documentIds: string[]) {
    const ordered = documentIds.map(id => {
        return {
            id,
            nId: normaliseDocumentId(id)
        }
    }).sort((a, b) => {
        // internal documents ordered first
        // followed by web links when citation
        // references both sources
        if (a.nId.isFile && b.nId.isWeb) return -1;
        if (a.nId.isWeb && b.nId.isFile) return 1;
        return 0;
    }).map(dd => dd.id);
    return ordered;
}

export function orderDocumentsByCitation(documents: SourceDocument[], citations: Citation[]) {
    try {
        const documentIds = documents.map(d => d.document_id);
        const dIDs = documents.map(d => normaliseDocumentId(d.document_id).id);

        const rankedCitationIds = [...citations]
            .sort((a, b) => a.start - b.start)
            .map(c => orderFileFirst(c.document_ids))
            .flat()
            .map(d => normaliseDocumentId(d).id)
            .filter((id) => dIDs.includes(id))
            .reduce((acc, cur) => {
                if (acc.includes(cur)) {
                    return acc;
                }
                return [...acc, cur];
            }, [] as string[]);

        const rankedDocuments = rankedCitationIds
            .map(nId => {
                const cId = rankedCitationIds.indexOf(nId);
                const rank = cId === -1 ? Infinity : cId;
                return {
                    rank,
                    nId,
                }
            })
            .sort((a, b) => {
                if (!isFinite(a.rank) && !isFinite(b.rank)) {
                    if (!a.nId.includes("web") && b.nId.includes("web")) return 1;
                    if (a.nId.includes("web") && !b.nId.includes("web")) return -1;
                    return 0;
                }
                return a.rank - b.rank;
            })
            .map(o => {
                const d = documents.find(dd => normaliseDocumentId(dd.document_id).id === o.nId);
                return d;
            })
            .filter(Boolean);

        const rankedDocumentIds = rankedDocuments.map(d => d?.document_id);
        // the remaining documents are unordered
        // this occurs when citations are streamed back sequentially
        // still, apply basic sort rule to show internal files first
        const unrankedDocuments = documentIds
            .filter(id => !rankedDocumentIds.includes(id)).sort((a, b) => a.localeCompare(b))
            .map(id => documents.find(d => d.document_id === id))
            .filter(Boolean);

        const orderedDocuments = [...rankedDocuments, ...unrankedDocuments].filter(notEmpty);
        return orderedDocuments;
    } catch (e) {
        handleError(e)
        return documents;
    }
}

export function SystemChatMessage({
    onFollowUpQuestionClick,
    onRetry,
    message,
    compact,
    showFollowUpQuestions,
    canRetry,
    question,
    sourceType
}: {
    onFollowUpQuestionClick: (question: string) => void,
    onRetry: () => void,
    message: SystemMessage,
    compact?: boolean
    showFollowUpQuestions?: boolean,
    canRetry: boolean,
    question: string,
    sourceType: SourceType
}) {
    const { user } = useContext(UserContext)
    const { showToast } = useContext(ToastContext)

    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)

    const isFinished = message.data.isFinished;
    const loadingAnswer = !message?.data?.text;

    const allDocuments = isFinished ? filterDocumentsByCited(message.data.documents || [], message.data.citations || []) : (message.data.documents || []);
    const filteredDocuments = getFilteredDocuments(allDocuments);
    const sortedDocuments = orderDocumentsByCitation(filteredDocuments, message.data.citations || []);
    const previewSourcesDocuments = (checkIfStringHasContent(message.data.text || '') ? sortedDocuments : []).filter(d => !d.document_id.includes("python"));

    const plannerSteps = (message.data.plan?.steps || []).map(s => ({
        description: s.description,
        tool: s.next_tools,
        answer: s.answer,
        doc_ref: s.doc_ref
    }))
    const plannerDocuments = message.data.planDocuments || null;
    const showPlanner = !!message.data.plan; // fixme
    const followUpQuestions = (message.data.followUpQuestions || [])

    const style = `flex justify-center items-center max-w-[710px] ${compact ? 'font-label' : 'font-body'}`

    const sendPositiveFeedback = () => {
        const tags: Record<string, any> = {
            is_positive: true
        }

        captureFeedback(
            {
                message: '',
                email: user?.email
            },
            {
                captureContext: {
                    tags: tags
                },
                includeReplay: false,
            },
        )

        showToast({
            variant: 'success',
            description: 'Feedback sent. Thank you for your feedback!',
            dismissable: true
        })
    }

    return (
        <div className={style}>
            <div className="w-full">
                {showPlanner && (
                    <div className="mt-4 flex-1 w-full">
                        <PlannerSteps plannerSteps={plannerSteps} plannerDocuments={plannerDocuments} sourceType={sourceType} />
                    </div>
                )}
                <div className="mt-4 w-full">
                    <PreviewSources documents={previewSourcesDocuments} compact={compact} maxIcons={3} message={message} loading={!isFinished} sourceType={sourceType} />
                </div>
                <div className="mt-6 mx-auto">
                    <div className={compact ? '' : "mx-6"}>
                        <FinalAnswer
                            isLoading={loadingAnswer && isFinished !== true}
                            isComplete={!!isFinished}
                            text={message.data?.text || ""}
                            citations={message.data.citations || []}
                            documents={sortedDocuments}
                            compact={compact}
                        />
                    </div>

                    {isFinished && (
                        <div className={`flex gap-5 justify-end ${compact ? '' : "mx-6"} pt-1`}>
                            {canRetry && (
                                <Button variant='tertiary' onClick={() => onRetry()}>
                                    <div className="flex gap-2 items-center">
                                        <RotateCcw className="size-6 shrink-0 stroke-[1.5px]" />

                                        Retry
                                    </div>
                                </Button>
                            )}

                            <div className="flex gap-2 items-center" onClick={() => sendPositiveFeedback()}>
                                <Button variant='tertiary'>
                                    <ThumbsUp className="size-6 shrink-0 stroke-[1.5px]" />
                                </Button>

                                <Button variant='tertiary' onClick={() => setOpenFeedbackDialog(true)}>
                                    <ThumbsDown className="size-6 shrink-0 stroke-[1.5px]" />
                                </Button>
                            </div>
                        </div>
                    )}

                    {followUpQuestions.length > 0 && showFollowUpQuestions && (
                        <div className={`flex flex-col gap-4 ${compact ? 'mt-8' : 'mt-12'} ${compact ? '' : 'mx-6'}`}>
                            <div className="flex gap-2 items-center">
                                <Sparkles className="w-6 h-6 shrink-0 stroke-[1.5px] stroke-system-body" />

                                <TypographyLabel className="text-system-body">
                                    Suggestions
                                </TypographyLabel>
                            </div>
                            <div className={`flex flex-col gap-2 ${compact ? 'pb-4' : 'pb-[60px]'}`}>
                                {[...followUpQuestions].splice(0, 3).map((question, index) => (
                                    <div key={`follow-up-question-${index}`}>
                                        {index != followUpQuestions.length && index !== 0 && (
                                            <div className="w-full h-[1px] bg-system-border-light mb-2"></div>
                                        )}

                                        <Button variant='tertiary' size='fit' className="flex gap-2 cursor-pointer w-full" onClick={() => onFollowUpQuestionClick(question)} disabled={!isFinished}>
                                            <TypographyBody isStrong={true} className="text-system-primary whitespace-pre-wrap text-left">
                                                {question}
                                            </TypographyBody>

                                            <ArrowRight className="w-6 h-6 ml-auto shrink-0" />
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <FeedbackDialog open={openFeedbackDialog} setOpen={setOpenFeedbackDialog} message={message} question={question} />
        </div>)
}

function LoadingDocuments() {
    return (
        <div className="source-documents">
            <div className="skeleton source-document" />
            <div className="skeleton source-document" />
            <div className="skeleton source-document" />
            <div className="skeleton source-document" />
        </div>
    )
}
function LoadingAnswer() {
    return (
        <div className="placeholder-lines">
            <div className="skeleton placeholder-line" />
            <div className="skeleton placeholder-line" />
            <div className="skeleton placeholder-line" />
            <div className="skeleton placeholder-line" />
            <div className="skeleton placeholder-line" />
        </div>
    )
}
export function SystemChatMessageSkeleton() {
    return (
        <div className="system-chat-skeleton">
            <div className="system-message-content">
                <LoadingDocuments />
                <LoadingAnswer />
            </div>
        </div>
    )
}

export function Chat() {
    return (
        <div className="chat-component-container">
            Deprecated: use /Assistant components instead
        </div>
    )
}
