import { TypographyBody, TypographyH4 } from "../ui/Typography"
import { Dossier, DossierDetail, DossierTab } from "@/types/types"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "../ui/button"
import { ChevronLeft, MessageCircleQuestion, NotebookPen } from "lucide-react"
import Divider from "../ui/divider"
import { useEffect, useState } from "react"
import { captialise, getTimestamp } from "@/utils/utils"
import { DossierContent } from "./DossierContent"
import { DossierFiles } from "./DossierFiles"
import { NotAvailableDialog } from "../Resources/NotAvailableDialog"
import { getDossierIcon } from "@/utils/components"
import { DossierDropdownMenu } from "./DossierDropdownMenu"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { addDossierReport, fetchDossierDetail } from "./dossierSlice"
import { requestCreateReport, requestReports, resetCreateReportData } from "../DocGen/docGenSlice"

interface DossierDetailPageProps {
    dossier: Dossier
    dossierDetail: DossierDetail | null
}

export const DossierPage = ({ dossier, dossierDetail }: DossierDetailPageProps) => {
    const createReport = useSelector((state: RootState) => state.docGen.createReport);

    const [selectedTab, setSelectedTab] = useState<DossierTab>('content')
    const [showNotAvailableDialog, setShowNotAvailableDialog] = useState(false)


    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const tabs: DossierTab[] = ['content', 'news', 'files']

    const createReportHandler = () => {
        dispatch(requestCreateReport({
            requestId: "docgen:create_report",
            params: {},
            timestamp: getTimestamp(),
        }))
    }

    useEffect(() => {
        if (createReport.data) {
            dispatch(addDossierReport({
                dossierId: dossier.id,
                reportId: createReport.data.id
            }))

            navigate(`/dossier/${dossier.id}/report/${createReport.data.id}`)

            dispatch(fetchDossierDetail(dossier.id))
            dispatch(resetCreateReportData())
        }
    }, [createReport.data])

    return (
        <>
            <div className="flex flex-col gap-10 tablet:mx-[88px] mt-4 sm:mt-0">
                <div>
                    <Button variant='tertiary' onClick={() => navigate('/dossiers')}>
                        <div className="flex gap-2">
                            <ChevronLeft className="size-6 shrink-0 stroke-[1.5px]" />

                            All dossiers
                        </div>
                    </Button>
                </div>

                <div className="flex flex-col tablet:flex-row gap-6">
                    <div className="p-3 flex flex-col gap-8 tablet:gap-6 justify-center tablet:justify-start mx-auto tablet:mx-0 tablet:min-w-[220px] laptop:min-w-[316px] tablet:max-w-[220px] laptop:max-w-[316px]">
                        <div className="flex tablet:flex-col gap-3 tablet:pb-6 items-center tablet:items-start w-fit tablet:w-full mx-auto tablet:mx-0">
                            <div className="size-10 flex items-center justify-center rounded-sm border border-system-border-regular bg-system-surface-light shrink-0">
                                {getDossierIcon(dossier.icon, 'size-4 tablet:!size-6')}
                            </div>

                            <div className="flex gap-2 items-end w-fit tablet:w-full">
                                <TypographyH4 className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[calc(100vw-176px)] tablet:whitespace-pre-wrap tablet:max-w-full tablet:line-clamp-2">
                                    {dossier.subject}
                                </TypographyH4>

                                <div className="tablet:mb-0.5 ml-0 tablet:ml-auto">
                                    <DossierDropdownMenu dossier={dossier} />
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-6">
                            <div className="flex tablet:flex-col gap-6 tablet:gap-3 mx-auto tablet:mx-0">
                                {tabs.map((tab) => {
                                    return (
                                        <div
                                            className={`tablet:px-3 py-1 h-fit w-fit border-b-2 tablet:border-l-2 tablet:border-b-0 ${selectedTab === tab ? 'border-system-primary' : 'border-transparent'}`}
                                            onClick={() => {
                                                if (tab === 'news') {
                                                    setShowNotAvailableDialog(true)
                                                } else {
                                                    setSelectedTab(tab)
                                                }
                                            }}
                                            key={tab}
                                        >
                                            <Button variant="tertiary" className="w-full">
                                                <TypographyBody isStrong={selectedTab === tab} className={`w-full text-left ${selectedTab === tab ? 'text-system-primary' : 'text-system-body'}`}>
                                                    {captialise(tab)}
                                                </TypographyBody>
                                            </Button>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="hidden tablet:!flex">
                                <Divider className="!bg-system-border-regular" />
                            </div>

                            <div className="flex tablet:flex-col gap-2 tablet:gap-6 mx-auto tablet:mx-0">
                                <Button variant='tertiary' className="hidden tablet:!flex" onClick={() => navigate(`/dossier/${dossier.id}/assistant/ask`)}>
                                    <div className="flex gap-2">
                                        <MessageCircleQuestion className="size-6 shrink-0 stroke-[1.5px] !stroke-link" />

                                        Ask Desia
                                    </div>
                                </Button>

                                <Button variant='tertiary' className="hidden tablet:!flex" onClick={createReportHandler}>
                                    <div className="flex gap-2">
                                        <NotebookPen className="size-6 shrink-0 stroke-[1.5px] !stroke-link" />

                                        Create report
                                    </div>
                                </Button>

                                <Button variant='secondary' className="flex tablet:!hidden" onClick={() => navigate(`/dossier/${dossier.id}/assistant/ask`)}>
                                    <div className="flex gap-2">
                                        <MessageCircleQuestion className="size-6 shrink-0 stroke-[1.5px] !stroke-link" />

                                        Ask Desia
                                    </div>
                                </Button>

                                <Button variant='secondary' className="flex tablet:!hidden" onClick={createReportHandler}>
                                    <div className="flex gap-2">
                                        <NotebookPen className="size-6 shrink-0 stroke-[1.5px] !stroke-link" />

                                        Create report
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>

                    {selectedTab === 'content' && <DossierContent dossier={dossier} dossierDetail={dossierDetail} createReportHandler={createReportHandler} />}
                    {selectedTab === 'files' && <DossierFiles dossier={dossier} dossierDetail={dossierDetail} />}
                </div>
            </div>

            <NotAvailableDialog open={showNotAvailableDialog} setOpen={(v) => setShowNotAvailableDialog(v)} variant='news' />
        </>
    )
}

export const DossierDetailPageContainer = () => {
    const { dossier_id } = useParams()

    const dossiers = useSelector((state: RootState) => state.dossier.dossiers);
    const dossierDetails = useSelector((state: RootState) => state.dossier.dossierDetails);
    const deleteReport = useSelector((state: RootState) => state.docGen.deleteReport);

    const [isDeletingReport, setIsDeletingReport] = useState(false)

    const dispatch = useDispatch<AppDispatch>()
    const dossier = dossiers.data?.find((v) => v.id === dossier_id)
    const dossierDetail = dossierDetails[dossier_id || '']?.data

    useEffect(() => {
        dispatch(requestReports({
            requestId: "docgen:list_reports_20_0",
            params: {
                limit: 20,
                offset: 0,
            },
            timestamp: getTimestamp(),
        }));

        if (!dossier_id) return
        dispatch(fetchDossierDetail(dossier_id))
    }, [])

    useEffect(() => {
        if (deleteReport.loading) {
            setIsDeletingReport(true)
        } else {
            if (dossier_id && isDeletingReport) {
                dispatch(fetchDossierDetail(dossier_id))
            }

            setIsDeletingReport(false)
        }
    }, [deleteReport.loading])

    if (!dossier) return null
    return (
        <DossierPage dossier={dossier} dossierDetail={dossierDetail} />
    )
}