import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { DossierDetail, QueryStatus, ResponseDocument, SourceType } from "../../types/types";
import { TypographyBody, TypographyLabel } from "../ui/Typography";
import DocumentSelectorDialog from "./DocumentSelectorDialog";
import { plural } from "@/utils/utils";
import Stack from "@/assets/Stack";
import { CustomTooltip } from "../CustomTooltip";
import { Button } from "../ui/button";
import { OptionDropdownMenu, OptionDropdownMenuContent, OptionDropdownMenuItem, OptionDropdownMenuLabel, OptionDropdownMenuSeparator, OptionDropdownMenuTrigger } from "../ui/option-dropdown-menu";
import ChevronDown from "@/assets/ChevronDown";
import { ChevronRight, FileSearch, X } from "lucide-react";
import InfoCircle from "@/assets/InfoCircle";
import { getFileIcon } from "@/utils/components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { listDocuments } from '../../components/Document/documentThunk';

const SelectedFileCell = ({ file, onRemove }: { file: ResponseDocument, onRemove: (file: ResponseDocument) => void }) => {
    return (
        <div className="flex gap-2 items-center px-2 py-1 rounded-sm border border-system-border-regular bg-system-surface">
            {getFileIcon(file.document_type_friendly, '!size-6 !shrink-0')}

            <TypographyLabel className="line-clamp-1">
                {file.document_name}
            </TypographyLabel>

            <Button variant='tertiary' onClick={() => onRemove(file)}>
                <X className="size-6 shrink-0 stroke-[1.5px]" />
            </Button>
        </div>
    )
}

export function SourceSelector({ dossierDetail, showFilePreview, sourceType }: { dossierDetail?: DossierDetail, showFilePreview?: boolean, sourceType: SourceType }) {
    const { settings, updateSettings } = useContext(UserContext);
    const documentStore = useSelector((state: RootState) => state.document);
    const dispatch = useDispatch<AppDispatch>()

    const [showDocumentSelector, setShowDocumentSelector] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)

    const sources = settings.assistant.sources[sourceType];
    // @ts-expect-error
    const selectedFiles = sources?.find((v) => v.id === 'internal-search')?.include || []
    // @ts-expect-error
    const selectedFileResources = (selectedFiles.map((v) => {
        return documentStore.files?.find((f) => f.document_id === v.id)
    }) || [])
        // @ts-expect-error
        .filter((v): v is ResponseDocument => Boolean(v))

    const dossierFileResources = (dossierDetail?.sources.map((v) => {
        return documentStore.files?.find((f) => f.document_id === v.resource_id)
    }) || []).filter((v): v is ResponseDocument => Boolean(v))

    const isWebSelected = Boolean(settings.assistant.sources[sourceType]?.find((v) => v.id === 'web-search'))
    const isInternalSearchSelected = Boolean(settings.assistant.sources[sourceType]?.find((v) => v.id === 'internal-search'))

    const isExpertMode = settings.assistant.mode === 'expert'
    const onlyDossierFilesSelected = selectedFileResources.length === dossierFileResources.length && selectedFiles.every((v: {
        title: string;
        id: string;
    }) => dossierFileResources?.find((r) => r.document_id === v.id))

    useEffect(() => {
        if (documentStore.fetchStatus === QueryStatus.INITIALISED) {
            dispatch(listDocuments())
        }
    }, [])

    return (
        <>
            <div>
                <div className="flex gap-6 items-center">
                    <OptionDropdownMenu open={showDropdown} onOpenChange={(v) => setShowDropdown(v)}>
                        <OptionDropdownMenuTrigger asChild>
                            <Button variant='tertiary' disabled={isExpertMode}>
                                <div className="flex gap-2">
                                    <Stack className="size-6 shrink-0" />

                                    <TypographyBody isStrong={true} className="hidden [@media(min-width:564px)]:!flex text-system-primary">
                                        {selectedFiles.length > 0 && !onlyDossierFilesSelected ? `Selected ${plural('file', selectedFiles.length)} (${selectedFiles.length})` : isInternalSearchSelected && isWebSelected ? 'All sources' : isInternalSearchSelected ? dossierDetail ? 'Dossier files only' : 'Library only' : 'Web only'}
                                    </TypographyBody>

                                    {!isExpertMode && (
                                        <ChevronDown className="size-6 shrink-0" />
                                    )}
                                </div>
                            </Button>
                        </OptionDropdownMenuTrigger>

                        <OptionDropdownMenuContent className="w-[313px]" align="start">
                            <OptionDropdownMenuLabel>
                                <div className="flex gap-2 items-center">
                                    Research across sources

                                    <div className="ml-auto" data-tooltip-id="research-across-sources" data-tooltip-place="right">
                                        <InfoCircle className="size-4" />
                                    </div>
                                </div>
                            </OptionDropdownMenuLabel>

                            <OptionDropdownMenuItem
                                selected={isInternalSearchSelected && isWebSelected}
                                onClick={() => {
                                    updateSettings({
                                        settings: {
                                            ...settings,
                                            assistant: {
                                                ...settings.assistant,
                                                sources: {
                                                    ...settings.assistant.sources,
                                                    [sourceType]: [
                                                        { id: 'internal-search' },
                                                        { id: 'web-search' }
                                                    ]
                                                }
                                            }
                                        }
                                    })
                                }}
                            >
                                All sources available
                            </OptionDropdownMenuItem>
                            <OptionDropdownMenuItem
                                selected={isInternalSearchSelected && !isWebSelected && (selectedFiles.length === 0 || onlyDossierFilesSelected)}
                                onClick={() => {
                                    const dossierFiles = dossierFileResources.map((v) => {
                                        return {
                                            title: v.document_name,
                                            id: v.document_id
                                        }
                                    })

                                    updateSettings({
                                        settings: {
                                            ...settings,
                                            assistant: {
                                                ...settings.assistant,
                                                sources: {
                                                    ...settings.assistant.sources,
                                                    [sourceType]: [
                                                        { id: 'internal-search', include: dossierFiles }
                                                    ]
                                                }
                                            }
                                        }
                                    })
                                }}
                            >
                                {dossierDetail ? 'Dossier files only' : `Library only`}
                            </OptionDropdownMenuItem>
                            <OptionDropdownMenuItem
                                selected={!isInternalSearchSelected && isWebSelected}
                                onClick={() => {
                                    updateSettings({
                                        settings: {
                                            ...settings,
                                            assistant: {
                                                ...settings.assistant,
                                                sources: {
                                                    ...settings.assistant.sources,
                                                    [sourceType]: [
                                                        { id: 'web-search' }
                                                    ]
                                                }
                                            }
                                        }
                                    })
                                }}
                            >
                                Web only
                            </OptionDropdownMenuItem>

                            <OptionDropdownMenuSeparator />

                            <OptionDropdownMenuLabel>
                                <div className="flex gap-2 items-center">
                                    Focused analysis

                                    <div className="ml-auto" data-tooltip-id="focused-analysis" data-tooltip-place="right">
                                        <InfoCircle className="size-4" />
                                    </div>
                                </div>
                            </OptionDropdownMenuLabel>

                            <OptionDropdownMenuItem
                                selected={selectedFiles.length > 0 && !onlyDossierFilesSelected}
                                onClick={() => {
                                    setShowDocumentSelector(true)
                                }}
                            >
                                <div className="flex gap-2 w-full">
                                    <FileSearch className="size-6 shrink-0 stroke-[1.5px]" />

                                    Selected files

                                    <ChevronRight className="size-6 shrink-0 stroke-[1.5px] ml-auto" />
                                </div>
                            </OptionDropdownMenuItem>

                            <CustomTooltip
                                id="research-across-sources"
                                className="!py-1 !px-3 !rounded-sm"
                                largeArrow={false}
                            >
                                <TypographyLabel className="w-[240px] text-system-body">
                                    Insights on companies, markets and people across web sources and internal knowledge
                                </TypographyLabel>
                            </CustomTooltip>

                            <CustomTooltip
                                id="focused-analysis"
                                className="!py-1 !px-3 !rounded-sm"
                                largeArrow={false}
                            >
                                <TypographyLabel className="w-[240px] text-system-body">
                                    In-depth data extraction, comparison, analysis, and summarization of a subset of files
                                </TypographyLabel>
                            </CustomTooltip>
                        </OptionDropdownMenuContent>
                    </OptionDropdownMenu>

                    {showFilePreview && !onlyDossierFilesSelected && (
                        <>
                            <div className="hidden laptop:!flex gap-2 items-center w-full overflow-hidden">
                                {[...selectedFileResources].slice(0, 2).map((file) => {
                                    return (
                                        <SelectedFileCell
                                            key={file.document_id}
                                            file={file}
                                            onRemove={(file) => {
                                                updateSettings({
                                                    settings: {
                                                        ...settings,
                                                        assistant: {
                                                            ...settings.assistant,
                                                            sources: {
                                                                ...settings.assistant.sources,
                                                                [sourceType]: [
                                                                    ...(settings.assistant.sources[sourceType]?.filter((v) => v.id !== 'internal-search') || []),
                                                                    // @ts-expect-error
                                                                    { id: 'internal-search', include: selectedFiles.filter((v) => v.id !== file.document_id) }
                                                                ]
                                                            }
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                    )
                                })}

                                {selectedFileResources.length - 2 > 0 && (
                                    <TypographyLabel className="whitespace-nowrap text-system-body">
                                        {`+ ${selectedFileResources.length - 2} other ${plural('file', selectedFileResources.length - 2)}`}
                                    </TypographyLabel>
                                )}
                            </div>

                            <div className="hidden sm:!flex gap-2 laptop:!hidden items-center w-full overflow-hidden">
                                {[...selectedFileResources].slice(0, 1).map((file) => {
                                    return (
                                        <SelectedFileCell
                                            key={file.document_id}
                                            file={file}
                                            onRemove={(file) => {
                                                updateSettings({
                                                    settings: {
                                                        ...settings,
                                                        assistant: {
                                                            ...settings.assistant,
                                                            sources: {
                                                                ...settings.assistant.sources,
                                                                [sourceType]: [
                                                                    ...(settings.assistant.sources[sourceType]?.filter((v) => v.id !== 'internal-search') || []),
                                                                    // @ts-expect-error
                                                                    { id: 'internal-search', include: selectedFiles.filter((v) => v.id !== file.document_id) }
                                                                ]
                                                            }
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                    )
                                })}

                                {selectedFileResources.length - 1 > 0 && (
                                    <TypographyLabel className="whitespace-nowrap text-system-body">
                                        {`+ ${selectedFileResources.length - 1} other ${plural('file', selectedFileResources.length - 1)}`}
                                    </TypographyLabel>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <CustomTooltip
                id="select-files"
                className="!py-1 !px-3 !rounded-sm"
                largeArrow={false}
            >
                <TypographyLabel>
                    Select files
                </TypographyLabel>
            </CustomTooltip>

            <DocumentSelectorDialog
                sourceType={sourceType}
                open={showDocumentSelector}
                dossierDetail={dossierDetail}
                setOpen={(v) => {
                    setShowDocumentSelector(v)
                }}
            />
        </>
    )
}
