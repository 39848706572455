import { useEffect, useState } from "react";
import { TypographyH3 } from "../ui/Typography";
import { ResourceFilter } from "./ResourceFilter";
import { QueryStatus, ResponseDocument } from "@/types/types";
import { CustomAlert } from "../CustomAlert";
import { MultiFileUpload } from "./MultiFileUpload";
import { AppDispatch, RootState } from "@/store/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchIntegrations } from "../Integration/integrationSlice";
import { FileTable } from "../Integration/FileTable";
import { transformResponseDocuments } from "@/utils/transformResponseDocuments";
import { ProcessingFiles } from "./ProcessingFiles";
import { sortDocumentsFn } from "../Document/utils";
import { listDocuments } from '../../components/Document/documentThunk';

export function ResourcesPage() {
    const dispatch = useDispatch<AppDispatch>();
    const { fetchStatus, files: resources, uploadStatus } = useSelector((state: RootState) => state.document);
    const integrations = useSelector((state: RootState) => state.integration.integrations)

    const [resourceFilter, setResourceFilter] = useState<string[]>(['shared', 'private', 'desia library']);
    const [processingFiles, setProcessingFiles] = useState<ResponseDocument[]>([])
    const [tempFiles, setTempFiles] = useState<ResponseDocument[]>([])

    const [processingFilesDismissed, setProcessingFilesDismissed] = useState(false)

    const loading = fetchStatus === QueryStatus.FETCHING;
    const errorFetching = fetchStatus === QueryStatus.ERROR_FETCHING;
    const errorUploading = uploadStatus === QueryStatus.ERROR_UPLOADING;

    const activeIntegrations = integrations.data?.filter((v) => v.integration_is_enabled && v.integration_has_setup) || []

    const filteredResources = resources.filter((r) => {
        const sharedFilter = resourceFilter.includes('shared') && r.document_is_part_of_desia_library === false && r.document_source !== 'integration' && r.document_visibility === 'organization'
        const privateFilter = resourceFilter.includes('private') && r.document_is_part_of_desia_library === false && r.document_source !== 'integration' && r.document_visibility === 'private'
        const integrationFilter = resourceFilter.includes(r.document_source_details?.integration_code_name)
        const desiaLibraryFilter = resourceFilter.includes('desia library') && r.document_is_part_of_desia_library === true

        const isReady = r.document_is_ready_to_use

        return (sharedFilter || privateFilter || integrationFilter || desiaLibraryFilter) && isReady
    })
    const sortedResources = [...filteredResources].sort(sortDocumentsFn);

    const shownProcessingFiles = [...resources.filter((v) => processingFiles.find((f) => f.document_id === v.document_id)), ...tempFiles.filter((v) => !resources.find((r) => r.document_id !== v.document_id))]

    useEffect(() => {
        dispatch(listDocuments())
        const filters = localStorage.getItem('library_filters')
        if (filters) {
            setResourceFilter(JSON.parse(filters))
        }
    }, [dispatch])

    useEffect(() => {
        if (resources.length > 0 && !processingFilesDismissed) {
            const temporaryUploadedFilesString = localStorage.getItem('temporary_uploaded_files')
            const temporaryUploadedFiles: ResponseDocument[] = temporaryUploadedFilesString ? JSON.parse(temporaryUploadedFilesString) : []
            const filteredTempFiles = temporaryUploadedFiles.filter((v) => !resources.find((r) => r.document_id === v.document_id))
            const shownFiles = [...resources.filter((v) => !v.document_is_ready_to_use || processingFiles.find((f) => f.document_id === v.document_id)), ...filteredTempFiles]

            setProcessingFiles(shownFiles)
            setTempFiles(filteredTempFiles)
            localStorage.setItem('temporary_uploaded_files', JSON.stringify(filteredTempFiles))
        }
    }, [resources, processingFilesDismissed])

    useEffect(() => {
        localStorage.setItem('library_filters', JSON.stringify(resourceFilter))
    }, [resourceFilter])

    const handleFilterChange = (filterName: string) => {
        if (resourceFilter.includes(filterName)) {
            setResourceFilter(resourceFilter.filter((v) => v !== filterName))
        } else {
            setResourceFilter([...resourceFilter, filterName])
        }
    }

    const handleUploadSubmit = () => {
        const temporaryUploadedFilesString = localStorage.getItem('temporary_uploaded_files')
        const temporaryUploadedFiles: ResponseDocument[] = temporaryUploadedFilesString ? JSON.parse(temporaryUploadedFilesString) : []
        const filteredTempFiles = temporaryUploadedFiles.filter((v) => !resources.find((r) => r.document_id === v.document_id))
        const shownFiles = [...processingFiles, ...filteredTempFiles]

        setTempFiles(filteredTempFiles)
        setProcessingFiles(shownFiles)
    }

    const sortedElements = transformResponseDocuments(sortedResources)

    return (
        <div className="max-w-full mt-10 mobile:mt-0 sm:max-w-[1200px] mx-auto flex flex-col gap-[72px]">
            <div className="flex flex-col gap-8">
                <div className="text-center">
                    <TypographyH3>Library</TypographyH3>
                </div>

                <MultiFileUpload onSubmit={handleUploadSubmit} />
            </div >

            {errorUploading && (
                <CustomAlert
                    variant="error"
                    title="We could not process all the files"
                    description="We failed to upload that file, please try again shortly"
                />
            )}

            <div className="flex flex-col gap-6">
                <ResourceFilter
                    integrations={activeIntegrations}
                    selectedFilter={resourceFilter}
                    handleFilterChange={handleFilterChange}
                />
                <FileTable
                    elements={sortedElements}
                    shownElements={sortedElements}
                    resources={sortedResources}
                    setElements={() => { }}
                    showCheckbox={false}
                    showHeader={true}
                    type='library'
                    loading={loading}
                    error={errorFetching}
                />
            </div>

            {shownProcessingFiles.length > 0 && (
                <ProcessingFiles
                    files={shownProcessingFiles}
                    onClose={() => {
                        setProcessingFilesDismissed(true)
                        setProcessingFiles([])
                    }}
                />
            )}
        </div >
    )
}

export function ResourcesPageContainer() {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(fetchIntegrations())
    }, [])

    return (
        <ResourcesPage />
    )
}
