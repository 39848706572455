import { RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBlocker, useParams } from "react-router-dom";
import { requestDeleteReport, requestEntityExtraction, requestReportById, requestSaveReport, updateLocalReportData, updateLocalReportTitle } from "../DocGen/docGenSlice";
import { getTimestamp } from "@/utils/utils";
import { ReportWriter } from "../DocGen/ReportWriter";
import { OutputData } from "@editorjs/editorjs";
import { RequestDocGenUpdateReport, WebSocketRequestWrapper } from "@/types/types";
import { convertReportToString } from "@/utils/docgen";
import { ReportSaveDialog } from "./ReportSaveDialog";
import { ReportWalkthroughDialog } from "./ReportWalkthroughDialog";

export function ReportWriterPage() {
    const { report_id, dossier_id } = useParams();

    const [ready, setReady] = useState(false);
    const [shouldBlock, setShouldBlock] = useState(false);
    const [shouldResetBlocker, setShouldResetBlocker] = useState(true);
    const [showReportWalkthrough, setShowReportWalkthrough] = useState(false)

    const dispatch = useDispatch();
    const blocker = useBlocker(({ currentLocation, nextLocation }) => shouldBlock && currentLocation.pathname !== nextLocation.pathname);

    const reportId = report_id || "";

    const editableReport = useSelector((state: RootState) => state.docGen.editReportById[reportId]);
    const reports = useSelector((state: RootState) => state.docGen.reports);
    const report = reports.data?.find((v) => v.id === report_id)

    const title = editableReport?.client?.title || "";
    const clientReport = editableReport?.client?.report;

    function handleSave() {
        const timestamp = getTimestamp();
        const request: WebSocketRequestWrapper<RequestDocGenUpdateReport> = {
            requestId: reportId,
            timestamp,
            params: {
                report_id: reportId,
                title,
                content: JSON.stringify(clientReport)
            }
        }
        dispatch(requestSaveReport(request));
        setShouldBlock(false)
    }

    function handleExtract() {
        dispatch(requestEntityExtraction({
            requestId: reportId,
            timestamp: getTimestamp(),
            params: {
                reportId: reportId,
                content: convertReportToString(clientReport, title)
            }
        }))
    }

    const deleteReport = () => {
        dispatch(requestDeleteReport({
            requestId: "docgen:delete_report",
            params: {
                report_id: reportId,
            },
            timestamp: getTimestamp(),
        }))
    }

    useEffect(() => {
        const shouldFetchReport = (!editableReport || !editableReport.server.loading) && Boolean(reportId);
        if (shouldFetchReport) {
            dispatch(requestReportById({
                requestId: reportId,
                timestamp: getTimestamp(),
                params: {
                    report_id: reportId
                }
            }))
        }
    }, [])

    // hack to allow editor to load blocks
    useEffect(() => {
        if (!ready && clientReport) {
            setTimeout(() => {
                setReady(true);
            }, 1_000);
        }
    }, [clientReport])

    useEffect(() => {
        if (report?.content === '') {
            setShouldBlock(true)
        }
    }, [report])

    useEffect(() => {
        const hideReportWalkthrough = localStorage.getItem('hide_report_walkthrough')

        if (hideReportWalkthrough !== 'true') {
            setShowReportWalkthrough(true)
        }
    }, [])

    if (!ready) {
        return null;
    }

    return (
        <>
            <ReportWriter
                key={`reportwriter_${reportId}`}
                reportId={reportId}
                title={title}
                report={clientReport}
                extractedEntities={editableReport?.client.extractedEntities}
                handleTitleChange={(title: string) => {
                    dispatch(updateLocalReportTitle({ reportId: reportId, title }));
                }}
                handleReportChange={(data: OutputData) => {
                    dispatch(updateLocalReportData({ reportId: reportId, data }));
                }}
                handleSave={handleSave}
                handleExtract={handleExtract}
                dossierId={report?.dossier_id || dossier_id}
            />
            <ReportSaveDialog
                open={blocker.state === 'blocked'}
                onConfirm={() => {
                    setShouldResetBlocker(false)
                    deleteReport()

                    blocker?.proceed?.()

                    // fixes "router allows one blocker at a time" error
                    setTimeout(() => {
                        blocker?.reset?.()
                    }, 500)
                }}
                onCancel={() => {
                    setTimeout(() => {
                        if (shouldResetBlocker) {
                            blocker?.reset?.()
                        }

                        setShouldResetBlocker(true)
                    }, 1)
                }}
            />
            <ReportWalkthroughDialog open={showReportWalkthrough} setOpen={setShowReportWalkthrough} />
        </>
    )
}
