import { ResponseChatStream, Citation, SourceDocument, SystemMessage, ResponseExpertModeStream } from "@/types/types";
import { checkWebLink, getFileId } from "./utils";
import { ReactNode } from "react";
import OneDriveIcon from "@/assets/OneDriveIcon";
import SharepointIcon from "@/assets/SharepointIcon";
import OneDriveIconCompact from "@/assets/OneDriveIconCompact";
import SharepointCompact from '../assets/SharepointCompact.png'
import IconDossier1 from '@/assets/icon-dossier-1.png'
import IconDossier2 from '@/assets/icon-dossier-2.png'
import IconDossier3 from '@/assets/icon-dossier-3.png'
import IconDossier4 from '@/assets/icon-dossier-4.png'
import IconDossier5 from '@/assets/icon-dossier-5.png'
import IconDossier6 from '@/assets/icon-dossier-6.png'
import IconDossier7 from '@/assets/icon-dossier-7.png'
import IconDossier8 from '@/assets/icon-dossier-8.png'
import IconDossier9 from '@/assets/icon-dossier-9.png'
import IconDossier10 from '@/assets/icon-dossier-10.png'
import { Badge } from "@/components/ui/badge";
import WordFile from "@/assets/WordFile";
import ExcelFile from "@/assets/ExcelFile";
import PowerPointFile from "@/assets/PowerPointFile";
import PDFFile from "@/assets/PDFFile";
import GenericFile from "@/assets/GenericFile";
import TextFile from "@/assets/TextFile";
import AudioFile from "@/assets/AudioFile";
import VideoFile from "@/assets/VideoFile";
import ImageFile from "@/assets/ImageFile";
import OpenFolder from "@/assets/OpenFolder";
import ClosedFolder from "@/assets/ClosedFolder";

// web sources could have the same document_id
// so instead use the url as the identifier
// edit: use ids + urls instead of urls only, there are cases where 2 sources link to the same url
function getGlobalDedupedId(d: SourceDocument, useWebIds: boolean = true) {
    const isWebLink = checkWebLink(d.url);
    if (isWebLink) {
        if (useWebIds) {
            return `${d.document_id}-${d.url}`;
        } else {
            return d.url
        }
    }
    // file
    const fileId = getFileId(d.document_id);
    return fileId;
}

export function getGlobalUniqueDocuments(documents: SourceDocument[], useWebIds?: boolean) {
    let deduped: SourceDocument[] = [];
    let dedupedIds = new Map();
    documents.forEach(d => {
        const dId = getGlobalDedupedId(d, useWebIds);
        if (!dedupedIds.has(dId)) {
            deduped.push(d);
            dedupedIds.set(dId, true);
        }
    });
    return deduped;
}

export function mergeIncomingStreamWithSystemMessage(prev: SystemMessage, incoming: ResponseChatStream) {
    let merged = { ...prev }
    switch (incoming.event_type) {
        case 'stream-start': {
            break;
        }
        case 'search-results': {
            merged.data.documents = incoming.body.documents;
            break;
        }
        case 'text-generation': {
            merged.data.text = incoming.body.text;
            break;
        }
        case 'citation-generation': {
            merged.data.citations = [
                ...(merged.data.citations || []),
                ...(incoming.body.citations || [])
            ]
            break;
        }
        case 'stream-end': {
            // todo: consider only setting finished flag
            merged.data.text = incoming.body.text;
            merged.data.documents = incoming.body.documents;
            merged.data.citations = incoming.body.citations;
            merged.data.isFinished = true;
            break;
        }
        case 'followup-question': {
            merged.data.followUpQuestions = incoming.body.questions
            break
        }

        default:
            break;
    }
    return merged;
}

export function filterDocumentsByCited(documents: SourceDocument[], citations: Citation[], skipPendingCitations = false) {
    if (skipPendingCitations) {
        return documents;
    }
    const citedDocumentIds = [...new Set(citations.map(c => c.document_ids).flat())];
    const filtered = documents.filter(d => {
        const shouldInclude = citedDocumentIds.includes(d.document_id);
        const isChart = d.document_id.includes('chart')
        return shouldInclude || isChart;
    })
    return filtered;
}

export const getFileIcon = (type: string, className?: string): ReactNode => {
    const style = `w-4 h-4 shrink-0 stroke-[1.5px] ${className}`

    switch (type.toLowerCase()) {
        case 'drive':
        case 'site':
        case 'user':
        case 'folder':
        case 'folder-open':
            return <OpenFolder className={style} />
        case 'folder-closed':
            return <ClosedFolder className={style} />
        case "pdf":
        case "application/pdf":
            return <PDFFile className={style} />
        case "text":
        case "text/plain":
        case "text/html":
        case "text/htm":
        case "text/css":
        case "text/markdown":
        case "markdown":
        case "application/xml":
        case "text/xml":
        case "application/rtf":
            return <TextFile className={style} />
        case "text/csv":
        case "csv":
            return <TextFile className={style} />
        case "word":
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return <WordFile className={style} />
        case "spreadsheet":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return <ExcelFile className={style} />
        case "presentation":
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            return <PowerPointFile className={style} />
        case "audio":
        case "audio/wav":
        case "audio/mpeg":
        case "audio/aiff":
        case "audio/x-aiff":
        case "audio/aac":
        case "audio/ogg":
        case "audio/flac":
            return <AudioFile className={style} />
        case "video":
        case "video/mp4":
        case "video/mpeg":
        case "video/quicktime":
        case "video/x-msvideo":
        case "video/x-flv":
        case "video/3gpp":
        case "video/webm":
        case "video/x-ms-vmv":
            return <VideoFile className={style} />
        case "image":
        case "image/png":
        case "image/jpeg":
        case "image/webp":
        case "image/heic":
        case "image/heif":
            return <ImageFile className={style} />
        default:
            return <GenericFile className={style} />
    }
}

export const getIntegrationIcon = (integration_code_name: string, compact?: boolean, className?: string) => {
    const style = `${className} shrink-0`
    switch (integration_code_name) {
        case 'microsoft-onedrive': return compact ? <OneDriveIconCompact className={style} /> : <OneDriveIcon />
        case 'microsoft-sharepoint': return compact ? <img src={SharepointCompact} className={style} /> : <SharepointIcon />
        default: return null
    }
}

export const getDossierIcon = (icon: string, className?: string) => {
    const style = `${className} size-6 shrink-0`
    switch (icon) {
        case '1': return <img className={style} src={IconDossier1} />
        case '2': return <img className={style} src={IconDossier2} />
        case '3': return <img className={style} src={IconDossier3} />
        case '4': return <img className={style} src={IconDossier4} />
        case '5': return <img className={style} src={IconDossier5} />
        case '6': return <img className={style} src={IconDossier6} />
        case '7': return <img className={style} src={IconDossier7} />
        case '8': return <img className={style} src={IconDossier8} />
        case '9': return <img className={style} src={IconDossier9} />
        case '10': return <img className={style} src={IconDossier10} />
        default: return <img className={style} src={IconDossier1} />
    }

}

export const getFileStatusBadge = (status: string) => {
    switch (status) {
        case "uploading": return <Badge variant="teal" className="whitespace-nowrap">Uploading</Badge>
        case "available":
        case "failed": return <Badge variant="red" className="whitespace-nowrap">Failed to process</Badge>
        case "normalizing":
        case "chunking":
        case "embedding":
        case "entity_recognition":
        default: return <Badge variant="purple" className="whitespace-nowrap">Processing</Badge>
    }
}

export function mergeIncomingExpertStreamWithSystemMessage(prev: SystemMessage, incoming: ResponseExpertModeStream): SystemMessage {
  const next: SystemMessage = {
      ...prev,
      data: {
          ...prev.data,
          ...incoming.body,
          planDocuments: incoming.body.documents || {},
          documents: [], // fixme
      }
  }
  return next;
}