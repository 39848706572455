import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Input } from "../ui/input";
import { DossierDetail, FileStructure, QueryStatus, ResponseDocument, SourceConnector, SourceType } from "@/types/types";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { TypographyBody } from "../ui/Typography";
import { UserContext } from "@/contexts/UserContext";
import { ChevronLeft, Sparkles, X } from "lucide-react";
import { plural, toggleElement } from "@/utils/utils";
import { FileTable } from "../Integration/FileTable";
import { transformResponseDocuments } from "@/utils/transformResponseDocuments";
import { CustomAlert } from "../CustomAlert";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { listDocuments } from "../Document/documentThunk";

const DocumentSelectorDialog = ({ trigger, dossierDetail, initialSelection, sourceType, onSave, open, setOpen, isDossierFileSelection }: { trigger?: ReactNode, dossierDetail?: DossierDetail, initialSelection?: string[], sourceType: SourceType, onSave?: (ids: string[]) => void, open: boolean, setOpen: (open: boolean) => void, isDossierFileSelection?: boolean }) => {
    const documentStore = useSelector((state: RootState) => state.document);
    const dispatch = useDispatch<AppDispatch>()
    const { settings, updateSettings } = useContext(UserContext);

    const [searchText, setSearchText] = useState('')
    const [tempSources, setTempSources] = useState(settings.assistant.sources)
    const [showSuggestions, setShowSuggestions] = useState(true)
    const [isInitial, setIsInitial] = useState(true)

    const loading = documentStore.fetchStatus === QueryStatus.FETCHING;
    const errorFetching = documentStore.fetchStatus === QueryStatus.ERROR_FETCHING;
    const resources = documentStore.files;

    const [elements, setElements] = useState<FileStructure[]>([])
    const [filteredElements, setFilteredElements] = useState<FileStructure[]>([])
    const [selectedElements, setSelectedElements] = useState<FileStructure[]>([])
    const [tempSelectedElements, setTempSelectedElements] = useState<FileStructure[]>([])

    const dossierFileResources = (dossierDetail?.sources.map((v) => {
        return documentStore.files?.find((f) => f.document_id === v.resource_id)
    }) || []).filter((v): v is ResponseDocument => Boolean(v))

    const suggestedDocuments = dossierDetail ? dossierFileResources : [...resources].splice(0, 5)

    const suggestedElements = useMemo(() => {
        return transformResponseDocuments(suggestedDocuments)
    }, [suggestedDocuments]).filter((v) => !tempSelectedElements.find((e) => e.internal_element_id === v.internal_element_id))

    const fileLimit = 15
    const fileSizeLimit = 30 * 10 ** 6
    const fileExceedsSizeLimit = tempSelectedElements.some((e) => {
        const document = documentStore.files.find((v) => v.document_id === e.document_id)

        return (document?.document_size_bytes || 0) > fileSizeLimit
    })

    useEffect(() => {
        if (documentStore.fetchStatus !== QueryStatus.SUCCEEDED) {
            dispatch(listDocuments())
        }
        setTempSources(settings.assistant.sources)
    }, [])

    useEffect(() => {
        if (!open) return

        const filteredDocuments = resources.filter((doc) => {
            if (!searchText) { return true }
            const splitText = searchText.toLowerCase().split(' ')
            const parents = doc.document_source_path_treeview || []

            const result = splitText.some((text) => doc.document_name.toLowerCase().includes(text)) || splitText.some((text) => parents.some((v) => v.element_name.toLowerCase().includes(text)))
            return result
        })

        const elements = transformResponseDocuments(filteredDocuments)
        setFilteredElements(elements)
    }, [resources, searchText, open])

    useEffect(() => {
        if (!open) return

        const selected = getSelectedElements(elements)

        setTempSelectedElements(selected)
    }, [elements, open])

    useEffect(() => {
        if (!open) return

        const readyResources = resources.filter((v) => v.document_is_ready_to_use)

        setElements(transformResponseDocuments(readyResources))
    }, [resources, open])

    useEffect(() => {
        if (!open) return
        if (!isInitial) return

        //@ts-expect-error
        const selectedFiles = settings.assistant.sources[sourceType]?.find((v) => v.id === 'internal-search')?.include || []
        
        const selected = !isDossierFileSelection ? elements.filter((v) => selectedFiles.some((f: { id: string, title: string }) => f.id === v.document_id)) : elements.filter((v) => initialSelection?.includes(v.document_id || ''))

        if (tempSelectedElements.length === 0) {
            setTempSelectedElements(() => selected)
        }

        selected.forEach((v) => {
            if (!v.is_included) {
                toggleElement(v, elements, setElements)
            }
        })

        setSelectedElements(() => selected)
    }, [elements, open, isInitial])

    const getSelectedElements = (elements: FileStructure[]): FileStructure[] => {
        let includedElements = []

        includedElements = elements.filter((v) => {
            return v.is_included && v.element_type === 'file'
        })

        return includedElements
    }

    const saveSettings = () => {
        const otherSources = tempSources[sourceType]?.filter((v) => v.id !== 'internal-search')
        const newSource: SourceConnector[] = [...otherSources, {
            id: 'internal-search', include: tempSelectedElements.map((v) => {
                return {
                    title: v.element_name,
                    id: v.document_id || '',
                }
            })
        }]

        if (!isDossierFileSelection) {
            updateSettings({
                settings: {
                    ...settings,
                    assistant: {
                        ...settings.assistant,
                        sources: {
                            ...settings.assistant.sources,
                            [sourceType]: newSource.filter((v) => v.id === 'internal-search')
                        }
                    }
                }
            })
        }

        const ids = tempSelectedElements.map((v) => v.document_id).filter((v): v is string => Boolean(v))
        onSave?.(ids)
    }

    return (
        <>
            <Dialog open={open} onOpenChange={(v) => {
                setOpen(v)

                setTempSources(settings.assistant.sources)

                if (!v) {
                    setIsInitial(true)
                }
            }}>
                {trigger && (
                    <DialogTrigger asChild>
                        {trigger}
                    </DialogTrigger>
                )}
                <DialogContent className="rounded-md !max-w-[calc(100%-48px)] tablet:!max-w-[951px] max-h-[calc(100vh-48px)] mobile:max-h-[calc(100vh-80px)] overflow-auto mobile:top-20 laptop:!top-30 !translate-y-0">
                    <DialogHeader>
                        <DialogTitle className="text-left">
                            {sourceType === 'ask' ? 'Select files and folders to analyse' : `Select which documents to use in ${dossierDetail?.subject}`}
                        </DialogTitle>
                        <DialogDescription className="font-body text-left whitespace-pre-wrap">
                            {sourceType === 'ask' ? 'You can select up to 15 files (max 30 MB each) to be used as sources for focused analysis.\n Supported file types include .pdf, .csv, .txt, .jpg, .png, .docx, .pptx, .xlsx.' : 'Search for the files you want to add or use the suggestions below.'}
                        </DialogDescription>
                        <div className="flex flex-col gap-10 pt-10">
                            {searchText && tempSelectedElements.length > 0 && (
                                <Button variant="tertiary" onClick={() => setSearchText('')}>
                                    <div className="flex gap-2">
                                        <ChevronLeft className="w-6 h-6 stroke-[1.5px]" />
                                        <TypographyBody isStrong={true}>
                                            {`${tempSelectedElements.length} ${plural('file', tempSelectedElements.length)} selected`}
                                        </TypographyBody>
                                    </div>
                                </Button>
                            )}

                            <div className="mobile:mx-auto">
                                <Input
                                    className="w-full mobile:w-[320px]"
                                    placeholder="Search by file name"
                                    isSearch={true}
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value)

                                        if (showSuggestions) {
                                            setShowSuggestions(false)
                                        }
                                    }}
                                    isCloseVisible={!!searchText}
                                    onCloseClick={() => setSearchText('')}
                                />
                            </div>

                            {tempSelectedElements.length > fileLimit && sourceType === 'ask' && (
                                <CustomAlert
                                    variant='error'
                                    title="You cannot select more than 15 files"
                                    description="Please remove the extra file to continue."
                                />
                            )}

                            {fileExceedsSizeLimit && sourceType === 'ask' && (
                                <CustomAlert
                                    variant='error'
                                    title="You cannot select a file larger than 30 MB"
                                    description="Please remove the file to continue."
                                />
                            )}

                            {searchText && (
                                <div className="overflow-y-auto">
                                    <FileTable
                                        elements={elements}
                                        shownElements={filteredElements}
                                        resources={resources}
                                        setElements={(v) => {
                                            setElements(v)
                                            setIsInitial(false)
                                        }}
                                        showCheckbox={true}
                                        showHeader={true}
                                        type='ask'
                                        loading={loading}
                                        error={errorFetching}
                                    />
                                </div>
                            )}

                            {!searchText && tempSelectedElements.length > 0 && (
                                <div className="flex flex-col gap-4">
                                    <TypographyBody className="text-system-body">
                                        {`${tempSelectedElements.length} ${plural('file', tempSelectedElements.length)} selected`}
                                    </TypographyBody>

                                    <div className="overflow-y-auto">
                                        <FileTable
                                            elements={elements}
                                            shownElements={tempSelectedElements}
                                            resources={resources}
                                            setElements={(v) => {
                                                setElements(v)
                                                setIsInitial(false)
                                            }}
                                            showCheckbox={true}
                                            showHeader={true}
                                            type='ask'
                                            loading={loading}
                                            error={errorFetching}
                                        />
                                    </div>
                                </div>
                            )}

                            {showSuggestions && !searchText && suggestedElements.length > 0 && (
                                <div className={`flex flex-col gap-6 p-4 bg-[#F5F8FA] rounded-lg ${loading ? 'max-w-full' : '!max-w-[calc(100vw-96px)]'}`}>
                                    <div className="flex gap-2.5 items-center">
                                        {!(sourceType === 'ask' && dossierDetail) && (
                                            <Sparkles className="w-5 h-5 stroke-[1.5px]" />
                                        )}

                                        <TypographyBody className="">{sourceType === 'ask' && dossierDetail ? 'Dossier files' : 'Suggested'}</TypographyBody>

                                        <Button variant={'tertiary'} className="ml-auto" onClick={() => setShowSuggestions(false)}>
                                            <X className="w-6 h-6 stroke-[1.5px]" />
                                        </Button>
                                    </div>
                                    <div className="overflow-y-auto">
                                        <FileTable
                                            elements={elements}
                                            shownElements={suggestedElements}
                                            resources={resources}
                                            setElements={(v) => {
                                                setElements(v)
                                                setIsInitial(false)
                                            }}
                                            showCheckbox={true}
                                            showHeader={true}
                                            type='ask'
                                            loading={loading}
                                            error={errorFetching}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {(selectedElements.length > 0 || tempSelectedElements.length > 0) && (
                            <DialogFooter className="pt-10">
                                <DialogClose>
                                    <Button variant="secondary" className="w-full">Cancel</Button>
                                </DialogClose>
                                {((tempSelectedElements.length <= fileLimit && !fileExceedsSizeLimit) || selectedElements.length > 0 || sourceType !== 'ask') && (
                                    <DialogClose>
                                        <Button className="w-full" onClick={() => saveSettings()}>
                                            Confirm selection
                                        </Button>
                                    </DialogClose>
                                )}
                            </DialogFooter>
                        )}
                    </DialogHeader>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DocumentSelectorDialog