import { useEffect, useState, useRef, useContext } from "react";
import { UserChatMessage, SystemChatMessage } from "../Chat";
import { ErrorMessage } from "../ErrorMessage";
import { useBlocker, useNavigate } from "react-router-dom";
import { AssistantAskMode, ChatMessage, Dossier, QueryState, SourceConnector, SystemMessage, UserMessage, StreamStatus, DossierDetail } from "../../types/types";
import { ChatBox } from "./ChatBox";
import { UserContext } from "@/contexts/UserContext";
import Divider from "../ui/divider";
import { WarnOnNavigate } from "../WarnOnNavigate";
import { Button } from "../ui/button";
import { ChevronLeft } from "lucide-react";
import { DossierBreadcrumb } from "../Dossier/DossierBreadcrumb";
import { TypographyBody } from "../ui/Typography";
import texture from '../../assets/bg-texture.png'
import { useSelector, useDispatch } from 'react-redux';
import type { RootState, AppDispatch } from '../../store/store';
import { actions as assistantActions } from './assistantSlice';
import { deleteLastMessage } from "./assistantThunk";

interface IProps {
    handleAsk: (args: { message: string, mode: AssistantAskMode }) => void;
    conversation: ChatMessage[];
    queryState: QueryState;
    sources: SourceConnector[]
    dossier?: Dossier
    dossierDetail?: DossierDetail
    title?: string
}

export function Conversation({
    handleAsk,
    conversation,
    queryState,
    sources,
    dossier,
    dossierDetail,
    title,
}: IProps) {
    const { settings, updateSettings } = useContext(UserContext);
    const [hasRendered, setHasRendered] = useState(false);
    const [isChatBoxCoveringText, setIsChatBoxCoveringText] = useState(false);
    const [shouldResetBlocker, setShouldResetBlocker] = useState(true);
    const [prevScrollY, setPrevScrollY] = useState(0);
    const [shouldShowBreadcrumb, setShouldShowBreadcrumb] = useState(true);
    const [isInitialScroll, setIsInitialScroll] = useState(true);
    const assistantStore = useSelector((state: RootState) => state.assistant);
    const dispatch = useDispatch<AppDispatch>();

    const blocker = useBlocker(({ currentLocation, nextLocation }) => assistantStore.state.streamStatus !== 'Ready' && conversation.at(-1)?.role === 'system' && currentLocation.pathname !== nextLocation.pathname);

    const navigate = useNavigate();
    const conversationBottomMarginRef = useRef<HTMLDivElement>(null);
    const conversationBottomRef = useRef<HTMLDivElement>(null);
    const lastMessage = conversation.length > 0 ? conversation[conversation.length - 1] : null;

    const askExpired = false; // fixme: checkAskExpired(lastMessage);
    const isBusy = (assistantStore.state.streamStatus !== 'Ready') && (!queryState.error) && !askExpired;
    const sourceType = dossier ? 'dossier' : 'ask'

    useEffect(() => {
        if (conversation.length > 0 && !isBusy && !hasRendered) {
            conversationBottomRef.current?.scrollIntoView({ behavior: 'instant', block: 'end' })

            setIsInitialScroll(false)
        } else if (conversation.length > 0) {
            setHasRendered(true)
        }
    }, [conversation])

    useEffect(() => {
        setHasRendered(false)
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const mainContainer = document.querySelector('.main-container-new')
            const scrollTop = mainContainer?.scrollTop || 0

            const scrolledTo = window.scrollY + window.innerHeight
            const clientWidth = document.body.clientWidth
            const emptyHeight = clientWidth > 568 ? clientWidth > 768 ? 160 : 80 : 40

            if (document.body.scrollHeight - emptyHeight < scrolledTo) {
                setIsChatBoxCoveringText(true)
            } else {
                setIsChatBoxCoveringText(false)
            }

            if (!isInitialScroll) {
                if (scrollTop > prevScrollY) {
                    setShouldShowBreadcrumb(false)
                } else {
                    setShouldShowBreadcrumb(true)
                }
            }
            setPrevScrollY(mainContainer?.scrollTop || 0)
        }

        window.addEventListener('scroll', handleScroll, { capture: true })

        return () => {
            window.removeEventListener('scroll', handleScroll, { capture: true })
        }
    }, [prevScrollY, isInitialScroll])


    useEffect(() => {
        const lastUserMessage = [...conversation].reverse().find((v) => v.role === 'system') as SystemMessage

        let mode = settings.assistant.mode

        if (lastUserMessage?.data.plan) {
            mode = 'expert'
        } else {
            mode = 'simple'
        }

        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    mode: mode
                }
            }
        })
    }, [conversation])

    useEffect(() => {
        dispatch(assistantActions.setStreamStatusWithMessage({ message: lastMessage, sourceConnectors: sources }))
    }, [lastMessage, sources])

    useEffect(() => {
        return () => {
            if (settings.assistant.sources[sourceType]?.find((v) => v.id === 'internal-search' && (v.include || []).length > 0)) {
                updateSettings({
                    settings: {
                        ...settings,
                        assistant: {
                            ...settings.assistant,
                            sources: {
                                ...settings.assistant.sources,
                                [sourceType]: [{ id: 'internal-search' }, { id: 'web-search' }]
                            }
                        }
                    }
                })
            }
        }
    }, [])

    const expertModeAvailable = true;

    const chatboxContainerStyle = `w-[calc(100%-64px)] sm:w-full fixed bottom-0 left-0 right-0 sm:left-auto sm:right-auto max-w-[calc(100%-64px)] md:max-w-[710px] mx-8 sm:mx-0`

    const handleRetry = (message: UserMessage) => {
        const conversationId = conversation.at(-1)?.conversationId

        if (!conversationId) return
        dispatch(assistantActions.removeLastMessage({ conversationId }))
        dispatch(deleteLastMessage(conversationId))

        handleAsk({ message: message.query, mode: settings.assistant.mode })
    }

    return (
        <>
            <div className="block [@media(min-width:1170px)]:flex gap-4">
                {!dossier && (
                    <Button variant='tertiary' className={`mb-4 [@media(min-width:1170px)]:mb-0 ml-0 lg:ml-20 h-fit ${dossier ? 'opacity-0' : ''}`} onClick={() => navigate('/assistant/ask')}>
                        <div className="flex gap-2">
                            <ChevronLeft className="h-6 w-6 stroke-[1.5px] shrink-0" />

                            Ask Desia chats
                        </div>
                    </Button>
                )}

                <div className={`mx-auto max-w-[710px] md:w-[710px]`}>
                    {dossier && shouldShowBreadcrumb && prevScrollY !== 0 && (
                        <div className={`absolute top-[60px] left-[50%] translate-x-[-50%] bg-system-surface w-full z-[2]`} style={{ backgroundImage: `url(${texture})` }}>
                            <DossierBreadcrumb
                                dossier={dossier}
                                component={
                                    <TypographyBody isStrong={true} className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[400px]">
                                        {title || 'New chat'}
                                    </TypographyBody>
                                }
                            />
                        </div>
                    )}

                    {dossier && (
                        <div className="bg-system-surface w-full z-[2] -mt-10" style={{ backgroundImage: `url(${texture})` }}>
                            <DossierBreadcrumb
                                dossier={dossier}
                                component={
                                    <TypographyBody isStrong={true} className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[400px]">
                                        {title || 'New chat'}
                                    </TypographyBody>
                                }
                            />
                        </div>
                    )}

                    <div className={`${dossier ? 'pt-7' : ''}`}>
                        {conversation
                            .map((chatMessage, idx) => {
                                if (chatMessage.role === 'user') {
                                    return <UserChatMessage key={`user__${idx}`} message={chatMessage} />
                                }
                                if (chatMessage.role == 'system') {
                                    const question = (conversation[idx - 1] as UserMessage).query

                                    return (
                                        <div key={`system_${idx}`}>
                                            <SystemChatMessage
                                                message={chatMessage}
                                                onFollowUpQuestionClick={(q) => {
                                                    conversationBottomMarginRef.current?.scrollIntoView({ behavior: 'smooth' })
                                                    handleAsk({ message: q, mode: settings.assistant.mode })
                                                }}
                                                onRetry={() => {
                                                    conversationBottomMarginRef.current?.scrollIntoView({ behavior: 'smooth' })
                                                    const userMessage = conversation[idx - 1] as UserMessage

                                                    if (userMessage) {
                                                        handleRetry(userMessage)
                                                    }
                                                }}
                                                showFollowUpQuestions={idx === conversation.length - 1}
                                                canRetry={conversation.length - 1 === idx}
                                                question={question}
                                                sourceType={sourceType}
                                            />
                                            {idx !== conversation.length - 1 && (
                                                <div className="mt-6 mb-8">
                                                    <Divider />
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                                return null
                            })}

                        {queryState.error && (
                            <ErrorMessage message={`We failed to stream the response: ${queryState.error}. Try asking again`} />
                        )}

                        {askExpired && (
                            <ErrorMessage message="missing response. If you refreshed the page, wait a minute and refresh. Otherwise, continue to ask further questions." />
                        )}
                    </div>
                    <div className={`h-[145px] w-full`} ref={conversationBottomRef}></div>
                    <div style={{ height: `${window.innerHeight * 0.2}px` }} className="bg-transparent"></div>
                    <div className={`h-[1px] w-full bg-transparent`} ref={conversationBottomMarginRef}></div>

                    <div className={chatboxContainerStyle}>
                        <ChatBox
                            expertModeAvailable={expertModeAvailable}
                            initialMessage=""
                            handleSubmit={(v) => {
                                conversationBottomMarginRef.current?.scrollIntoView({ behavior: 'smooth' })
                                setTimeout(() => {
                                    handleAsk(v)
                                }, 100)
                            }}
                            isConversation={true}
                            showBorder={!isChatBoxCoveringText}
                            additionalControls={true}
                            conversationId={lastMessage?.conversationId}
                            isFinished={!isBusy}
                            canStop={true}
                            sourceType={dossier || dossierDetail ? 'dossier' : 'ask'}
                            dossierDetail={dossierDetail}
                        />
                    </div>
                </div>

                {!dossier && (
                    <div className="w-[230px] h-[1px]">

                    </div>
                )}
            </div>
            <WarnOnNavigate
                open={blocker.state === 'blocked'}
                onConfirm={() => {
                    setShouldResetBlocker(false)
                    blocker?.proceed?.()
                    dispatch(assistantActions.setStreamStatus({ status: StreamStatus.Ready }))
                    // fixes "router allows one blocker at a time" error
                    setTimeout(() => {
                        blocker?.reset?.()
                    }, 500)
                }}
                onCancel={() => {
                    setTimeout(() => {
                        if (shouldResetBlocker) {
                            blocker?.reset?.()
                        }

                        setShouldResetBlocker(true)
                    }, 1)
                }}
            />
        </>
    )
}
