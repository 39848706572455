import { SourceDocument } from "@/types/types"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import { getGlobalUniqueDocuments } from "@/utils/components";
import { Source } from "../Source";
import { Checkbox } from "../ui/checkbox";
import { Dispatch, SetStateAction, useState } from "react";

interface IProps {
    documents: SourceDocument[];
    showTabs: boolean
    selectable?: boolean
    error?: boolean
    selectedDocuments?: SourceDocument[]
    setSelectedDocuments?: Dispatch<SetStateAction<SourceDocument[]>>
}

// todo: consider unique documents as prop
// todo: support tabbing through links
export function Sources({ documents, showTabs, selectable, error, selectedDocuments = [], setSelectedDocuments }: IProps) {
    const [selectedTab, setSelectedTab] = useState<'all' | 'internal' | 'webpages'>('all')

    const uniqueDocuments = getGlobalUniqueDocuments(documents);
    const webpages = uniqueDocuments.filter(d => d.document_id.toLowerCase().includes("web"));
    const internalDocs = uniqueDocuments.filter(d => !d.document_id.toLowerCase().includes("web"));

    const hasWebpages = webpages.length > 0;
    const hasInternalDocs = internalDocs.length > 0;

    const isAllWebSelected = webpages.every((v) => selectedDocuments?.includes(v))
    const isAllInternalSelected = internalDocs.every((v) => selectedDocuments?.includes(v))

    const isSomeWebSelected = webpages.some((v) => selectedDocuments?.includes(v))
    const isSomeInternalSelected = internalDocs.some((v) => selectedDocuments?.includes(v))

    const areSourcesUnselected = selectedDocuments.length !== documents.length

    const toggleSource = (document: SourceDocument) => {
        if (selectedDocuments?.includes(document)) {
            setSelectedDocuments?.(selectedDocuments.filter((v) => v !== document))
        } else {
            setSelectedDocuments?.([...selectedDocuments, document])
        }
    }

    const toggleWebpages = () => {
        if (isAllWebSelected) {
            setSelectedDocuments?.(selectedDocuments.filter((v) => !webpages.includes(v)))
        } else {
            setSelectedDocuments?.([...selectedDocuments.filter((v) => !webpages.includes(v)), ...webpages])
        }
    }

    const toggleInternalDocuments = () => {
        if (isAllInternalSelected) {
            setSelectedDocuments?.(selectedDocuments.filter((v) => !internalDocs.includes(v)))
        } else {
            setSelectedDocuments?.([...selectedDocuments.filter((v) => !internalDocs.includes(v)), ...internalDocs])
        }
    }

    return (
        <div className="flex flex-col gap-6">
            {showTabs ?
                <Tabs defaultValue='all' value={selectedTab} className="rounded-md">
                    <TabsList className="mb-6">
                        <TabsTrigger value="all" onClick={() => setSelectedTab('all')}>All</TabsTrigger>

                        {hasInternalDocs && <TabsTrigger value="internal" onClick={() => setSelectedTab('internal')}>
                            <div className="flex gap-2">
                                {selectable && (
                                    <Checkbox
                                        checked={isAllInternalSelected || isSomeInternalSelected}
                                        partial={isSomeInternalSelected && !isAllInternalSelected}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            toggleInternalDocuments()
                                        }}
                                    />
                                )}

                                Library
                            </div>
                        </TabsTrigger>}
                        {hasWebpages && <TabsTrigger value="webpages" onClick={() => setSelectedTab('webpages')}>
                            <div className="flex gap-2">
                                {selectable && (
                                    <Checkbox
                                        checked={isAllWebSelected || isSomeWebSelected}
                                        partial={isSomeWebSelected && !isAllWebSelected}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            toggleWebpages()
                                        }}
                                    />
                                )}

                                Web
                            </div>
                        </TabsTrigger>}
                    </TabsList>

                    <TabsContent value="all" className={`flex flex-col gap-4 overflow-y-auto ${areSourcesUnselected ? !error ? 'max-h-[calc(100vh-100px-200px-80px)] mobile:max-h-[calc(100vh-160px-200px-80px)]' : 'max-h-[calc(100vh-100px-200px-80px-160px)] mobile:max-h-[calc(100vh-160px-200px-80px-160px)]' : 'max-h-[calc(100vh-100px-200px)] mobile:max-h-[calc(100vh-160px-200px)]'}`}>
                        {uniqueDocuments.map(d =>
                            <Source
                                key={d.document_id}
                                id={d.document_id}
                                url={d.url}
                                title={d.title}
                                text={d.text}
                                showBorder={true}
                                metadata={d.doc_metadata}
                                showCheckbox={selectable}
                                selected={selectedDocuments.includes(d)}
                                onSelect={() => toggleSource(d)}
                            />
                        )}
                    </TabsContent>

                    {hasWebpages && (
                        <TabsContent value="webpages" className={`flex flex-col gap-4 overflow-y-auto ${areSourcesUnselected ? !error ? 'max-h-[calc(100vh-100px-200px-80px)] mobile:max-h-[calc(100vh-160px-200px-80px)]' : 'max-h-[calc(100vh-100px-200px-80px-160px)] mobile:max-h-[calc(100vh-160px-200px-80px-160px)]' : 'max-h-[calc(100vh-100px-200px)] mobile:max-h-[calc(100vh-160px-200px)]'}`}>
                            {webpages.map(d =>
                                <Source
                                    key={d.document_id}
                                    id={d.document_id}
                                    url={d.url}
                                    title={d.title}
                                    text={d.text}
                                    showBorder={true}
                                    metadata={d.doc_metadata}
                                    showCheckbox={selectable}
                                    selected={selectedDocuments.includes(d)}
                                    onSelect={() => toggleSource(d)}
                                />
                            )}
                        </TabsContent>
                    )}

                    {hasInternalDocs && (
                        <TabsContent value="internal" className={`flex flex-col gap-4 overflow-y-auto ${areSourcesUnselected ? !error ? 'max-h-[calc(100vh-100px-200px-80px)] mobile:max-h-[calc(100vh-160px-200px-80px)]' : 'max-h-[calc(100vh-100px-200px-80px-160px)] mobile:max-h-[calc(100vh-160px-200px-80px-160px)]' : 'max-h-[calc(100vh-100px-200px)] mobile:max-h-[calc(100vh-160px-200px)]'}`}>
                            {internalDocs.map(d =>
                                <Source
                                    key={d.document_id}
                                    id={d.document_id}
                                    url={d.url}
                                    title={d.title}
                                    text={d.text}
                                    showBorder={true}
                                    metadata={d.doc_metadata}
                                    showCheckbox={selectable}
                                    selected={selectedDocuments.includes(d)}
                                    onSelect={() => toggleSource(d)}
                                />
                            )}
                        </TabsContent>
                    )}
                </Tabs>
                :
                <div className="flex flex-col gap-4">
                    {uniqueDocuments.map(d =>
                        <Source
                            key={d.document_id}
                            id={d.document_id}
                            url={d.url}
                            title={d.title}
                            text={d.text}
                            showBorder={true}
                            metadata={d.doc_metadata}
                            showCheckbox={selectable}
                            selected={selectedDocuments.includes(d)}
                            onSelect={() => toggleSource(d)}
                        />
                    )}
                </div>
            }
        </div>
    )
}
