import { ResponseChartData } from "@/types/types"
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts"
import { ChartHeader } from "./ChartHeader";
import { useRef } from "react";

export const ResponseBarChart = ({ parsedData, series, options, compact }: {
    parsedData: ResponseChartData
    series: ApexAxisChartSeries,
    options: ApexOptions,
    compact?: boolean
}) => {
    const ref = useRef<HTMLDivElement>(null)

    const barOptions: ApexOptions = {
        ...options,
        chart: {
            ...options.chart,
            type: 'bar',
        },
        yaxis: {
            ...options.yaxis,
            labels: {
                // @ts-expect-error
                ...options.yaxis?.labels,
                offsetX: -16,
            },
        },
        stroke: {
            colors: ['transparent'],
            width: 2,
        },
    }

    const barAmount = series.reduce((a, b) => a + b.data.length, 0)

    return (
        <div className={`flex flex-col gap-4 p-4 ${compact ? 'mt-8' : 'mt-10'} relative group`}>
            <ChartHeader chartRef={ref} parsedData={parsedData} compact={compact} />

            <div className="relative w-full h-[300px]">
                <div className={`absolute max-w-full ${barAmount > 10 ? 'w-fit' : 'w-full'} laptop:max-w-[1200px] laptop:left-[50%] laptop:-translate-x-[50%] overflow-x-scroll laptop:overflow-x-visible overflow-y-visible h-[300px]`}>
                    <div ref={ref} className={`${barAmount > 10 ? 'w-[1200px]' : 'w-full'} h-full [&_.apexcharts-tooltip]:!border-none [&_.apexcharts-tooltip]:!rounded-sm [&_.apexcharts-tooltip]:!bg-system-secondary`}>
                        <Chart
                            options={barOptions}
                            series={series}
                            type='bar'
                            width='100%'
                            height='100%'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}