import { Integration, IntegrationStage } from "@/types/types";
import { ChevronLeft } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { Button } from "../ui/button";
import { IntegrationSelection } from "./IntegrationSelection";
import { WarnOnAction } from "../WarnOnAction";
import { TypographyBody, TypographyH3 } from "../ui/Typography";
import { checkIntegrationFlag, getIntegrationName } from "@/utils/utils";
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext";
import { UserContext } from "@/contexts/UserContext";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

export interface IntegrationDetailProps {
    integration: Integration
}

export const IntegrationDetailPage = ({ integration }: IntegrationDetailProps) => {
    const [currentPage, setCurrentPage] = useState<IntegrationStage>('authentication')

    const navigate = useNavigate()

    return (
        <div className="flex flex-col gap-2 mx-[88px]">
            <WarnOnAction action={() => {
                if (currentPage === 'authentication') {
                    navigate('/integrations')
                } else {
                    setCurrentPage('authentication')
                }
            }} warn={currentPage === 'file-selection'}>
                <Button variant='tertiary' >
                    <div className="flex gap-2">
                        <ChevronLeft className="h-6 w-6 stroke-[1.5px] shrink-0" />

                        All integrations
                    </div>
                </Button>
            </WarnOnAction>

            <div className="flex flex-col gap-12 max-w-[600px] mx-auto">
                <div className="flex flex-col gap-2 text-center">
                    <TypographyH3>
                        {`Manage ${getIntegrationName(integration.integration_code_name)} integration`}
                    </TypographyH3>

                    <TypographyBody className="text-system-body">
                        {`Selected files / folders will only be accessible by members of your team`}
                    </TypographyBody>
                </div>

                <IntegrationSelection integration={integration} />
            </div>
        </div>
    )
}

export const IntegrationDetailPageContainer = () => {
    const { integration_code_name } = useParams();

    const integrations = useSelector((state: RootState) => state.integration.integrations)

    const ff = useContext(FeatureFlagContext)
    const { user } = useContext(UserContext)

    const integrationFlag = checkIntegrationFlag(ff, user)

    const selectedIntegration = integrations.data?.find((v) => v.integration_code_name === integration_code_name)

    if (!selectedIntegration) return null

    if (!integrationFlag) return null

    return (
        <IntegrationDetailPage integration={selectedIntegration} />
    )
}