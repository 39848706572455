import { useEffect } from "react";
import shortid from "shortid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getTimestamp } from "../../utils/utils";
import { NewAsk } from "./NewAsk";
import { AssistantAskMode } from "../../types/types";
import { useSelector, useDispatch } from 'react-redux';
import type { RootState, AppDispatch } from '../../store/store';
import { actions as assistantActions } from './assistantSlice';


export function Ask() {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { dossier_id } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const initialQuery = searchParams.get("q");
    const initialMode = searchParams.get("mode") as AssistantAskMode;
    const trigger = searchParams.get("trigger");

    const dossiers = useSelector((state: RootState) => state.dossier.dossiers);
    const dossier = dossier_id ? dossiers.data?.find((v) => v.id === dossier_id) : undefined

    function handleSubmit({ message, mode }: { message: string, mode: AssistantAskMode }) {
        const requestId = `new_ask__${shortid()}`;
        dispatch(assistantActions.newAsk({
          requestId,
          question: message,
          timestamp: getTimestamp(),
          mode,
      }))
        const encodedQuestion = encodeURIComponent(message);

        if (dossier_id) {
            navigate(`/dossier/${dossier_id}/assistant/ask/new/?request_id=${requestId}&q=${encodedQuestion}&mode=${mode}&trigger=true`);
        } else {
            navigate(`/assistant/ask/new/?request_id=${requestId}&q=${encodedQuestion}&mode=${mode}&trigger=true`);
        }
    }

    useEffect(() => {
        if (trigger === "true" && initialQuery && initialMode) {
            setSearchParams(next => {
                next.delete("trigger");
                return next;
            })
            handleSubmit({ message: initialQuery, mode: initialMode });
        }
    }, [])

    const expertModeAvailable = true;
    const hasSourceSelector = true;

    return <NewAsk
        dossier={dossier}
        expertModeAvailable={expertModeAvailable}
        hasSourceSelector={hasSourceSelector}
        handleSubmit={handleSubmit}
    />
}
